export const PATHS: { [key: string]: string } = {
    LOGIN: 'login',
    // quote order module
    ORDER_QUOTE: 'orders-quotes',
    SERVICE_TYPE: 'service-type',
    COMPANY_DETAILS: 'company-details',
    ADDITIONAL_INFO: 'additional-info',
    PATENT_DETAILS: 'patent-details',
    SELECT_COUNTRY: 'select-country',
    SELECT_EP_COUNTRY: 'select-EPcountry',
    FILE_UPLOAD: 'file-upload',
    SUMMARY: 'summary',
    ORDER_QUOTE_DETAILS: 'order-quote-details',
    QO_FILE_UPLOAD: 'file-upload',
    LANGUAGE_SELECTION: 'language-selection',
    STANDARD_DETAILS: 'standard',

    // common
    CREATE: 'create',
    EDIT: 'edit',
    VIEW: 'view',

    // Company paths
    COMPANIES: 'companies',
    COMPANY_PROFILE: 'company-profile',
    BILLING_INFO: 'billing-info',
    CONTACTS: 'contacts',
    CREATE_CONTACT: 'create-contact',
    RATES: 'rates',

    // USER Paths
    USERS: 'users',
    STAFF_USER: 'staff',
    VENDOR_USER: 'vendor',
    CONTACT_USER: 'contact',

    STAFF_USER_PROFILE: 'staff-user-profile',
    // STAFF_USER_FILE_UPLOAD: 'file-upload',

    CONTACT_USER_PROFILE: 'contact-user-profile',
    CONTACT_BILLING_INFO: 'contact-billing-info',

    VENDOR_USER_PROFILE: 'vendor-user-profile',
    VENDOR_BILLING_INFO: 'vendor-billing-info',
    // VENDOR_USER_FILE_UPLOAD: 'file-upload',
    VENDOR_RATES: 'vendor-rate',
    VENDOR_MINIMUM_CHARGES: 'vendor-minimum-charges',

    // Task Paths
    TASK_MANAGEMENT: 'task-management',

    // Create Task Paths

    CREATE_TASK: 'create-task',
    TASK_DETAILS: 'task-details',

    // account settings
    ACCOUNT_SETTINGS: 'account-settings',

    // Accounting Paths
    ACCOUNT_INVOICES: 'accounting/invoices',
    ACCOUNT_BILLS: 'accounting/bills',
    INVOICES: 'invoices',
    BILLS: 'bills',

    // PV Settings
    PV_SETTINGS: 'patent-vault-settings'
};
